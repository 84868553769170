$(function(){
    let e = $('[data-map-container]');
    if (!e.length) return ;
    let point = (e.data('map-container') || '').split(',');
    point = [parseFloat(point[0] || 0), parseFloat(point[1] || 0)];

    ymaps.ready(init);
    function init(){
        let m = new ymaps.Map("ymap", { center: point, zoom: 16 });
        let mark = new ymaps.Placemark(point, {
        }, {
            iconLayout: 'default#image',
            iconImageHref: 'static/images/map-icon.png',
            iconImageSize: [93/2, 245/2],
            iconImageOffset: [-20, -94]
        });
        m.geoObjects.add(mark);
    }
}());
