import 'owl.carousel';
import 'slick-carousel';

import sizes from "../scss/_settings/_settings.scss";
window.sizes = sizes;

require('../scss/main.scss');
require('../../node_modules/slick-carousel/slick/slick.scss');

require('../components/jquery.maskedinput');

require('./parts/closed-tab.js');
require('./parts/base.js');
require('./parts/application.js');
require('./parts/directions.js');
require('./parts/calendar.js');
require('./parts/call-me.js');
require('./parts/map.js');
require('./parts/slider-galery');
require('./parts/slider-doctors');
require('./parts/editor.js');

require('../fonts/Ubuntu/face.css');
require('../fonts/Cambria/face.css');
