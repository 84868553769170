$(function () {
  let openDirection = function (id) {
    $('[data-direction], [data-direction-content], [data-mobile-cats]').removeClass('selected');
    $('[data-direction-content="'+id+'"], [data-direction="'+id+'"], [data-mobile-cats="'+id+'"]').addClass('selected');
    $(document).trigger('highlight');
  };

  $(document).on('click', '[data-direction]', function () {
    if (this.dataset['dbclose'] && this.classList.contains('selected')) {
      $('[data-direction], [data-direction-content], [data-mobile-cats]').removeClass('selected');
      $(document).trigger('highlight');
    }
    openDirection(this.dataset['direction']);
  });

  $(document).on('click', '.mobile-navigator .direction.drop-down', function () {
    $('.type.drop-down').removeClass('opened')
  });

  $(document).on('click', '.mobile-navigator .direction.drop-down [data-direction]', function () {
    $('[data-dir-name]').html(': ' + this.dataset['name']);
  });

  // Desktop
  let headers = '.d-menu [data-direction] [data-category]';

  let scrollToItem = function (head) {
    console.log('+', head);
    let cnt = head.parent();
    cnt.animate({
      scrollTop: head.offset().top
      - cnt.offset().top + cnt.scrollTop()
      - parseInt(cnt.css('paddingTop')) - ($(window).width() <= sizes.M ? 0 : 14)
    }, 200, function (){ $(document).trigger('highlight') });
  };

  $(document).on('click', headers, function () {
    let id = this.dataset['category'];
    scrollToItem($('.desktop-only [data-direction-content] [data-head="'+id+'"]'));
  });

  //scrollToItem($('.desktop-only [data-direction-content] [data-head="1"]'));

  $(document).on('click', '[data-class-toggle] [data-category]', function () {
    let id = this.dataset['category'];
    let head = $('.desktop-only [data-direction-content] [data-head="'+id+'"]');
    $('body,html').animate({
      scrollTop: head.offset().top
    }, 250);
  });

  $(document).on('highlight', function (e) {
    let area = $('.desktop-only [data-direction-content].selected .scroll-area');
    let pad = (area && area.offset()) ? area.offset().top + parseInt(area.css('paddingTop')) : 0;
    let optimal = {dist: Infinity, e: undefined};

    area.find('[data-head]').each(function () {
      let dist = Math.abs($(this).offset().top - pad);
      if (optimal.dist > dist) {
        optimal.dist = dist;
        optimal.e = this.dataset['head'];
      }
    });

    console.log('>', optimal)

    $(headers).removeClass('selected');
    $('[data-cat-name]').html('');

    if (optimal.e) {
      let name = $('[data-category="'+optimal.e+'"]').addClass('selected').html();
      // $('[data-cat-name]').html(': ' + name);
    }
  });

  $(headers).removeClass('selected');
  $('[data-category="1"]').addClass('selected');


  if ($(headers).length > 0) {
    let currentOffset = -1;
    setInterval(function () {
      let top = $('[data-direction-content].selected .scroll-area').scrollTop();
      if (currentOffset != top) {
        currentOffset = top;
        $(document).trigger('highlight');
      }
    }, 50);
  }

  //$(document).trigger('highlight');

  let finder = $('[data-search]').data('search');

  $('[data-search]').on('keyup change focus', function () {
    let str = $(this).val();
    if (str === "") {
      $(this).parent().removeClass('process');
    } else {
      $(this).parent().addClass('process');
    }

    $('[data-search-results]').html('');
    if (str.length == 0) return ;

    let result = [];
    let find = str.split(' ');
    finder.map(function (direction) {
      direction.categories.map(function (category) {
        category.services.map(function (service) {
          let rate = 0;
          let words = service.name.split(' ');

          for (let w in words) {
            for (let f in find) {
              if (words[w].toLowerCase().indexOf(find[f].toLowerCase()) !== 0) continue;
              if (words[w] === find[f]) rate += 2;
              rate += 1;
            }
          }

          if (rate > 0) result.push([
            rate, service.name, direction.id, category.id, service.id
          ]);
        })
      });
    });

    result = result.sort((a,b) => (a[0] > b[0]) ? -1 : ((b[0] > a[0]) ? 1 : 0));

    let html = result.slice(0, 7).map(function (one) {
      return '<div class="result" data-dir="' + one[2] + '" data-cat="' + one[3] + '" data-id="' + one[4] + '">' + one[1] + '</div>';
    }).join('');

    $('[data-search-results]').html(html);
  }).on('blur', function () {
    setTimeout(function () {
      $(this).parent().removeClass('process');
    }, 350);
  });

  $(document).on('mousedown', '[data-search-results] [data-id]', function (e) {
    $('[data-direction="'+this.dataset['dir']+'"]').trigger('click');
    $('[data-search]').val("").trigger('change');
    $('.direction.drop-down').removeClass('opened')

    let target = $('[data-ser="'+this.dataset['id']+'"]');
    target.addClass('attention');
    setTimeout(function () {
      target.removeClass('attention');
    }, 250);
    scrollToItem(target);
  });

  let l = location.hash.split('#');
  if (l.length > 1) {
    let name = decodeURIComponent(l[1]);
    let ID = $('[data-direction][data-name="'+name+'"]').data('direction');
    if (!ID) ID = $('[data-direction][data-slug="'+name+'"]').data('direction');
    if (!ID) return;
    openDirection(ID);
    $('[data-dir-name]').html(': ' + name);

    $('html, body').animate({
      scrollTop: 200
    }, 350);
  }

  // Fix profile
  let p = $('[data-sidebar-profile]');
  if (p.length > 0 && $(window).width() > sizes.M) {
    let c = $('[data-cnt-p]');
    $(window).on('scroll', () => {
      if ($(document).scrollTop() + 400 > $('body').height() - $(window).height()) {
        p.css('opacity', 0)
      } else {
        p.css('opacity', 1)
      }

      if (c.offset().top > $(document).scrollTop() + 30) {
        p.removeClass('fix').css({
          right: 0
        });

      } else {
        p.addClass('fix').css({
          right: ($(window).width() - c.width())/2 + 'px'
        });
      }

    });
  }
});
