$(function () {
  window.request = function (url, data, after) {
    after = after || function () {};
    return $.post(url, Object.assign(data, window._token), after, 'json');
  };

  window.rub = function (num) {
    return Math.round(num).toLocaleString('ru-RU') + ' ₽';
  };

  let name = 'custom-modal';
  let m = $('[data-modal="'+name+'"]');

  let remember = function (name) {
    let date = new Date();
    date.setTime(date.getTime() + (100 * 24 * 60 * 60 * 1000));
    let expires = "; expires="+date.toGMTString();
    document.cookie = 'remember=' + name + expires;
  };

  if (m.length > 0) {
    let show = true;
    document.cookie.split('; ').map(item => {
      let one = item.split('=');
      if (one[0] === 'remember' && one[1] && one[1] == m.data('id')) show = false;
    });

    if (show) {
      $('[data-modal="'+name+'"].call-me').addClass('visible');
      setTimeout(function () {
        $('body').attr('data-modal', name);
      }, 20);
      $('[data-modal="'+name+'"] [data-modal-close]').click(() => { remember(m.data('id')) });
    }
  }

  $(document).on('click', '[data-more-news]:not(:disabled)', function (){
    $(this).attr('disabled', true).html('Загрузка...');
    $.get('', {offset: $('[data-news-item]').length}, (ret) => {
      $(this).attr('disabled', false).html($(this).data('text'));
      const items = $('<div>' + ret + '</div>').find('[data-news-list]');
      $('[data-news-list]').append(items.html());
      if (items.find('[data-news-item]').length < 6) $(this).remove();
    });
  });

  $("[data-stroll-to]").click(function() {
    let to = $(this).data('stroll-to');
    $([document.documentElement, document.body]).animate({
      scrollTop: $(`[data-scroll-id="${to}"]`).offset().top
    }, 1000);
  });
  
  let HH = location.hash.length > 1 ? location.hash.slice(1) : false;
  let obj = $(`#${HH}`);
  if (HH && obj.length > 0) {
    $([document.documentElement, document.body]).animate({
      scrollTop: obj.offset().top
    }, 1000);
  }
  
  
});
