
require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/disable-selection');

document.execCommand("defaultParagraphSeparator", false, "br");

$(function(){
  let ql = {};
  let cnt = '[data-editable-content]';

  /* ---------------------------------------------------------------------- */
  // Save functions
  let timer = false;
  let delay = 3 * 1000;

  let url = () => {
    let l = location.href;
    return l + (l.indexOf('?') == -1 ? '?' : '&') + $.param({ save: true });
  };

  let send = (type) => {
    if (timer) clearTimeout(timer);

    let data = [];
    $(cnt).find('[data-type].editable-wrapper').each(function(){
      let tp = $(this).data('type');
      let block = {'type' : tp, 'data' : {}};
      $(this).find('[data-name]').each(function(){
        block.data[$(this).data('name')] = $(this).val() || $(this).html();
      });
      if (tp === 'text') {
        let id = $(this).find('.text').data('quill');
        block.data.text = ql[id].root.innerHTML;
      }
      data.push(block);
    });

    $.post(url(), {
      type: type,
      post: { data: data, settings: {} }
    }, (page) => {
      //if (type === 'publish') location.reload();
      //let html = $('<div/>').append(page).find(cnt + ' ');
      //id = html.find(block).data('id');
      //$(block).html(html.find(block).html());
    });
  };

  let autosave = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => { send('autosave'); }, delay);
  };

  /* ---------------------------------------------------------------------- */
  // Insert block's After
  let changed = function(e){
    let url = e.find('[data-name="url"]').val();
    let src = '';

    if (url.indexOf('youtu') !== -1) {
      let ID = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if (ID != null) src = 'https://www.youtube.com/embed/' + ID[1];
    }
    if (url.indexOf('vimeo') !== -1) {
      let ID = url.split('vimeo.com/')
      if (ID[1]) src = 'https://player.vimeo.com/video/' + ID[1]
    }
    if (src !== '') {
      e.find('[data-name="src"]').val(src)
      e.find('iframe').attr('src', src);
    }
  };
  let after = function (e) {
    e.find('[data-name="url"]').keydown(
      (event) => { changed(e); }
    ).change(
      (event) => { changed(e); }
    );

    e.find('input[name="file"]').on('change', function(){
      let fileinput = $(this);
      let target = $(this).parent();
      let size = $(this).data('size').split('/');
      if (!this.files[0]) return ;

      let fr = new FileReader();
      fr.addEventListener('load', (h) => {
        let boxw = target[0].offsetWidth;
        let boxh = target[0].offsetHeight;

        let img = new Image();
        img.onload = function() {
          let bh = boxh;
          let bw = boxw;

          let axis;
          if (this.width/this.height > boxw/boxh) {
            bw = this.width/this.height * boxh;
            axis = 'x';
          } else {
            bh = this.height/this.width * boxw;
            axis = 'y';
          }

          let canvas = $('<canvas/>')
            .attr('width', bw * 2)
            .attr('height', bh * 2)
            .css('width', bw + 'px')
            .css('height', bh + 'px')
            .appendTo(target);

          let ctx = canvas[0].getContext('2d');
          ctx.drawImage(img, 0, 0, bw * 2, bh * 2);

          let offsets = {left: 0, top: 0};
          canvas.draggable({
            scroll: false,
            axis: axis,
            stop: function (e, h) {
              offsets = h.position;
            },
            drag: function (e, h) {
              if (h.position.left > 0) h.position.left = 0;
              if (h.position.left < boxw - bw) h.position.left = boxw - bw;
              if (h.position.top > 0) h.position.top = 0;
              if (h.position.top < boxh - bh) h.position.top = boxh - bh;
            }
          });

          target.append('<button data-img-load>Загрузить</button> <button data-img-remove>Отмена</button>');

          target.find('[data-img-remove]').click(function () {
            target.find('canvas, button').remove();
          });

          target.find('[data-img-load]').click(function () {
            $(this).html('...');
            let box = target.find('.media');

            let off = {
              left: offsets.left * parseInt(size[0])/box[0].offsetWidth,
              top:  offsets.top * parseInt(size[1])/box[0].offsetHeight
            };

            console.log(offsets);
            console.log(off);

            $.post(url(), {
              type: 'image',
              size: size,
              data: h.target.result,
              offsets: off
            }, (path) => {
              target.find('canvas, button').remove();
              if (!path.image) return alert('Ошибка загрузки картинки :(');
              box.css({ backgroundImage: 'url("'+path.image+'")' });
              target.find('[type="hidden"][data-name]').val(path.image);
              fileinput.val('');
              autosave();
            }, "json");
          });
        };

        img.src = h.target.result;
      });
      fr.readAsDataURL( this.files[0] );
    });
  };

  /* ---------------------------------------------------------------------- */
  let tpl = (name) => {
    return $('[data-editable-templates] [data-id="'+name+'"]').html()
  };

  let sortable = () => {
    if ($(cnt).sortable("instance")) $(cnt).sortable('destroy');
    $(cnt).sortable({ axis: 'y', handle: '.draggable', stop: autosave });
  };

  $(document).on('click', '[data-editable-pane] .add-block-button', function(e) {
    let id = $(this).data('id');
    let block = $(tpl(id));

    block.appendTo(cnt);
    block.data('type', id).find('[contenteditable="true"]').on('keyup', autosave);

    sortable();
    after(block);
  });

  $(document).on('click', '.pane .remove', function(e) {
    $(this).parent().parent().remove();
    sortable();
    autosave();
  });

  $(cnt).find('.editable-wrapper').each(function(){
    after($(this));
  });

  $('[contenteditable="true"]').on('keyup', autosave);

  sortable();

  window.addEventListener('saver', function(){
    send('save');
  });

});



