$(function () {
    let accent = null;

    $(document).on('click', '[data-scroll-to]', function () {
        let to = $('#' + $(this).data('scroll-to'));
        $([document.documentElement, document.body]).animate({
            scrollTop: to.offset().top
        }, 800);
    });


    $(document).on('click', '[data-writer]', function () {
        $(this).closest('.drop-down').find('[data-writer-current]').html(this.innerHTML);

        $('[data-info]').val($(this).data('w-one'));
        $('[data-text]').val(this.innerHTML);

        $('[data-wh="time"]').html(this.innerHTML);

        $('[data-writer-init]').removeClass('disabled');

        $('[data-record-wrapper]').addClass('accent');
        clearInterval(accent);
        accent = setTimeout(function () {
            $('[data-record-wrapper]').removeClass('accent');
        }, 1000 + 50); // 600 + 350 + 100 for borders
    });

    $(document).on('click', '[data-write-start]', function () {
        $(this).closest('.drop-down').find('[data-writer-current]').html(this.innerHTML);

        $('[data-wh="title"]').html(this.innerText);

        $('[data-paste]').removeClass('disabled');

        let variants = $('[data-hidden-selectors="' + $(this).data('write-start') + '"]').html();
        $('[data-paste-selectors]').html(variants);
    });

    $(document).on('click', '[data-wf-id]', function () {
        let item = $('[data-w="'+$(this).data('wf-id')+'"]');
        let head = $('[data-ws]');

        if ($(window).width() <= sizes.XS) {
            $('html, body').animate({
                scrollTop: item.offset().top
            }, 200);
        } else {
            head.animate({
                scrollTop: item.offset().top - head.offset().top + head.scrollTop()
            }, 200);
        }
    });

    // Автовыбор 1 даты из списка
    let L = $('.profile-desc [data-writer]:last-child()');
    if (L.length) {
        $('[data-info]').val(L.data('w-one').split(':')[0] + ':_');
        console.log($('[data-info]'));
        //setTimeout(() => { $('.drop-down').removeClass('opened'); }, 10);
    }
});
