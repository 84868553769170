$(function () {
    $('[data-phone]').mask("+7 (999) 999-99-99", {
        completed: function () {
            $(this).addClass('completed')
        }
    });

    $(document).on('click', '[data-modal-close]', function () {
        $('body').attr('data-modal', '');
        setTimeout(function () {
            $('[data-modal].call-me').removeClass('visible');
        }, 350);
    });
    $(document).on('click', '[data-modal-name]:not(.disabled)', function () {
        let name = $(this).data('modal-name');
        $('[data-modal="'+name+'"].call-me').addClass('visible');
        setTimeout(function () {
            $('body').attr('data-modal', name);
        }, 20);
    });
    
    $(document).on('click', '[data-call-me-send-page]:not(.done)', function () {
        let cnt = $(this).closest('[data-form-wrapper]')
        let n = cnt.find('input[data-name]');
        let p = cnt.find('input[data-phone]');
        let b = $(this);

        let name = n.val();
        if (name.length < 2) return n.focus();

        let phone = p.val();
        if (!p.hasClass('completed')) return p.focus();

        $.post(this.dataset['to'], {
            name: name,
            phone: phone,
            info: cnt.find('input[data-info]').val(),
            text: ''
        }, function (ret) {
            b.addClass('done');
        });
    });
    
    $(document).on('click', '[data-call-me-send], [data-record-send]', function () {
        let prefix = '.call-me[data-modal="'+$('body').data('modal')+'"] ';
        if (!$(prefix).length) prefix = '';

        let n = $(prefix + 'input[data-name]');
        let p = $(prefix + 'input[data-phone]');
        let b = $(this);

        let name = n.val();
        if (name.length < 2) return n.focus();

        let phone = p.val();
        if (!p.hasClass('completed')) return p.focus();

        $(prefix + ' .call-window').append('<div class="result"></div>');

        $.post(this.dataset['to'], {
            name: name,
            phone: phone,
            info: $(prefix + 'input[data-info]').val(),
            text: $(prefix + 'input[data-text]').val()
        }, function (ret) {
            $(prefix + ' .call-window .result').html(ret).addClass('visible');

            setTimeout(function () {
                $('body').attr('data-modal', '');
                $(prefix + ' .call-window .result').remove();
            }, 3 * 1000)

            return ;

            if (ret !== "success") return alert(ret);
            b.addClass('done');

            setTimeout(function () {
                $('body').attr('data-modal', '');
                b.removeClass('done');
            }, 3 * 1000)
        });
    });
});
