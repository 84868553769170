$(function () {
  $(document).ready(function () {
    let parent = $(".card-doctors-list");
    $(parent).slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      appendArrows: '.slider-btns',
      nextArrow: `<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="9.009" height="15.335" viewBox="0 0 9.009 15.335">
          <path id="Path_243" data-name="Path 243" d="M110.243,7.069,103.418.245a.841.841,0,0,0-1.186,0l-.5.5a.84.84,0,0,0,0,1.186l5.731,5.73L101.723,13.4a.841.841,0,0,0,0,1.187l.5.5a.841.841,0,0,0,1.186,0l6.831-6.83a.847.847,0,0,0,0-1.191Z" transform="translate(-101.478 0)" fill="#fff"/>
        </svg>
        
        </button>`,
      prevArrow: `<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="9.009" height="15.335" viewBox="0 0 9.009 15.335">
          <path id="Path_243" data-name="Path 243" d="M110.243,7.069,103.418.245a.841.841,0,0,0-1.186,0l-.5.5a.84.84,0,0,0,0,1.186l5.731,5.73L101.723,13.4a.841.841,0,0,0,0,1.187l.5.5a.841.841,0,0,0,1.186,0l6.831-6.83a.847.847,0,0,0,0-1.191Z" transform="translate(-101.478 0)" fill="#fff"/>
        </svg>
        </button>`,
      // responsive: [{
      //   breakpoint: 768,
      //   settings: {
      //     centerPadding: '16.9%',
      //   }
      // }]

    });





  });

});