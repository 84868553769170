$(function () {
    window.click_event = false;

    window.goal = function (name) {
        console.log('> Goal: ' + name);

        if (typeof(gtag) != "undefined") {
            gtag('event', name, {'event_category': 'action'});
        } else {
            console.log('! gtag fail');
        }

        if (typeof(yaCounter53483851) != "undefined") {
            yaCounter53483851.reachGoal(name);
        } else {
            console.log('! yaCounter fail');
        }
    };
    /* --------------------------------------------------------------------- */

    $(document).on('click', '[data-goal]', function (e) {
        goal(this.dataset['goal']);
    });

    /* --------------------------------------------------------------------- */

    let drop = false, t = null;

    $(document).on('click', 'div', function (e) {
        if ($(this).closest('.drop-down').length > 0) drop = true;
        if (!t) t = setTimeout(function () {
            t = null;
            if (!drop) $('.drop-down').removeClass('opened');
            drop = false;
        }, 15);
    });

    /* --------------------------------------------------------------------- */
    let contacts_show = function () {
        let e = $('[data-contacts-block]');
        if (e.length === 0) return false;
        return $('html, body').animate({ scrollTop: e.offset().top }, 1000);
    };

    $(document).on('click', '[data-contacts-link]', function (e) {
        e.preventDefault();
        if (!contacts_show()) location.href = '/#contacts';
    });

    /* --------------------------------------------------------------------- */
    $('.scrolled').on('mousemove', function (e) {
        let d = $(this).offset().left + $(this).width() - e.clientX + 40;
        if (d > 0 && d < 8) $('body').addClass('pointer'); else $('body').removeClass('pointer');
    }).on('mouseout', function (e) {
        $('body').removeClass('pointer');
    });

    /* --------------------------------------------------------------------- */
    $('[data-stars]').each(function () {
        let stars = $(this);
        let initial = this.dataset['stars'];

        let fill = function (val) {
            stars.find('.one-star').removeClass('fill').each(function (n, i) {
                if (n + 1 <= val) $(this).addClass('fill');
            });
        };

        if (this.dataset['owner']) {
            fill(this.dataset['owner']);
            stars.find('.stars-rate').addClass('voted');
        } else {
            fill(this.dataset['stars']);
        }

        stars.find('.one-star').hover(function () {
            fill(this.dataset['val']);
        }, function () {
            fill(initial);
        });

        stars.find('.one-star').click(function () {
            let vote = $(this).index() + 1;
            stars.find('.stars-rate').addClass('voted');
            initial = vote;

            fill();

            $.post('/rate-object', $.extend(window._token, {
                rate: vote,
                to: stars.data('object')
            }), function (res) {
                stars.find('[data-total]').html(res.total);
                stars.find('[data-mean]').html(res.mean);
            }, "json");
        });
    });


    /* --------------------------------------------------------------------- */
    $(document).on('click', '[data-spec-opened]', function () {
        $(this).parent().toggleClass('opened');
    });

    $(document).on('click', '[data-class-toggle]:not(.disabled)', function () {
        $(this).toggleClass($(this).data('class-toggle'));
        if (window.click_event) return ;
        window.click_event = true;
        setTimeout(function () {
            window.click_event = false;
        }, 350);
    });

    /* --------------------------------------------------------------------- */
    $(document).on('click', '[data-menu-opener]', function () {
        $('body').toggleClass('menu-opened');
    });


    /* --------------------------------------------------------------------- */
    $(document).on('click', '[data-spec-sel].opened [data-spec]', function () {
        let val = $(this).wrapAll('<div>').parent().html();
        $(this).closest('[data-spec-sel]').find('[data-current]').html(val);

        let name = this.dataset['spec'];
        if (!name) return ;

        $('[data-profile-spec]').each(function () {
            $(this).hide();
            if (($(this).data('profile-spec')).indexOf(name) !== -1) $(this).show();
        });
    });

    /* --------------------------------------------------------------------- */
    $('[data-close-closer-tab]').click(function () {
        $('[data-closer-tab]').addClass('hidden');
    });
    const HS = {one: 'час', few: 'часа', many: 'часов'};
    const MS = {one: 'минуту', few: 'минуты', many: 'минут'};
    let pr = function (num) {
        if (num == 1) return 'one';
        if (num > 1 && num < 5) return 'few';
        if (num > 4 && num < 20) return 'many';

        let l = (num + '')[(num + '').length - 1];
        if (l == 1) return 'one';
        if (l > 1 && l < 5) return 'few';
        return 'many';
    };

    let minsWriter = function(mins){
        let M = mins%60;
        let H = mins < 60 ? 0 : parseInt((mins - M)/60);

        $('[data-timer-h]').html(H > 9 ? H : ('0' + H));
        $('[data-timer-m]').html(M > 9 ? M : ('0' + M));

        $('[data-timer-h-help]').html(HS[pr(H)]);
        $('[data-timer-m-help]').html(MS[pr(M)]);
    };

    let mins = parseInt($('[data-closer-tab]').data('closer-tab'));
    minsWriter(mins);
    setInterval(function () {
        mins -= 1;
        if (mins == 0) $('[data-closer-tab]').addClass('hidden');
        minsWriter(mins);
    }, 60 * 1000);
    setInterval(function () {
        $('[data-closer-tab] .separator').css('opacity', 0);
        setTimeout(function () {
            $('[data-closer-tab] .separator').css('opacity', 1);
        }, 1000);
    }, 2000);

    /* --------------------------------------------------------------------- */
    let mouse = [0,0];
    $(document).on('mousedown', '[data-href]', function (e) {
        mouse = [e.clientX, e.clientY];
    });
    $(document).on('mouseup', '[data-href]', function (e) {
        if (Math.abs(mouse[0] - e.clientX) + Math.abs(mouse[1] - e.clientY) > 16) return ;
        location.href = $(this).data('href');
    });


    /* --------------------------------------------------------------------- */
    let slides = $('.directions-points [data-slide]'), current = 0;
    let autoplay = setInterval(function () {
        current += 1;
        if (current >= slides.length) current = 0;
        $('[data-slide]').removeClass('current');
        $('[data-slide="' + current + '"]').addClass('current');
    }, 7000);

    $('[data-slide]').click(function () {
        clearInterval(autoplay);
        $('[data-slide]').removeClass('current');
        $('[data-slide="' + this.dataset['slide'] + '"]').addClass('current');
    });


    /* --------------------------------------------------------------------- */
    $('[data-slider]').each(function () {
        let name = $(this).data('slider');
        let target = $(this);
        let n = $('[data-next="'+name+'"]');
        let p = $('[data-prev="'+name+'"]');

        let items = target.find('.item-wrapper:not(.spacer)').length;

        if ($(window).width() >= sizes.S && items <= 3) {
            target.find('.spacer').remove();
            $('[data-counter="'+name+'"]').parent().parent().remove();
        }

        target.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            arrows: false,
        }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            let s = nextSlide + 1;
            $('[data-counter="'+name+'"]').html(s < 10 ? ('0' + s) : s);
            if (nextSlide == 0) p.addClass('disabled'); else p.removeClass('disabled');
            if (nextSlide == items-1) n.addClass('disabled'); else n.removeClass('disabled');
        });

        n.click(function () { target.slick('slickNext'); });
        p.click(function () { target.slick('slickPrev'); });
    });

    $(document).on('click', '[data-banner]', function () {
        $('[data-sale-box-title]').html(this.dataset['text']);
        $('input[data-info]').val(this.dataset['banner']);
    });

    $('[data-nav][href="'+location.pathname+'"]').addClass('current');
    if (location.hash === '#contacts') contacts_show();



    // SCROLL TO ONLINE
    let online_show = function () {
        let e = $('[data-online-block]');
        if (e.length === 0) return false;
        return $('html, body').animate({ scrollTop: e.offset().top - 50 }, 300);
    };

    if (location.hash === '#online') online_show();

    $(document).on('click', '[data-online-link]', function (e) {
        e.preventDefault();
        if (!online_show()) location.href = '/#online';
    });
});

